import React from 'react';
import cn from 'classnames';

import { toCents, toDollars } from 'utils/moneyUtils';

import Input from 'components/Input';
import Label from 'components/Label';
import DropdownSelectMany from 'components/DropdownSelectMany';
import { WorkModelSelectMany } from 'components/WorkModelSelectMany';

import styles from './CandReqInputs.scss';

const CandReqInputs = ({
  candReq,
  handleCandReqInputChange,
  handleCandReqRoleChange,
  handleCandReqWorkModelChange,
  pickerOptions = {},
  isProfileLocked,
  autofocusFields,
  className,
}) => {
  const {
    oteCents: ote = 0,
    salaryCents: salary = 0,
    roles = [],
    locationNotes = '',
    workModel = [],
  } = candReq || {};

  const { role: roleOptions = [] } = pickerOptions || {};

  const rolesOptions = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const selectedRoles = roles.map((role) => ({ role, type: 'role' }));

  const rolesInputProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: (value) =>
      handleCandReqRoleChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'roles',
          optionType: value.optionType,
        },
      }),
    optionsLarge: true,
    source: rolesOptions,
    name: 'roles',
    key: 'roles',
    isLocation: true,
    selectedValues: selectedRoles || '',
    defaultLabel: 'Click to select a role',
    autoFocus: autofocusFields.includes('roles'),
    highlight: autofocusFields.includes('roles'),
    disabled: isProfileLocked,
  };

  const oteInputProps = {
    disabled: isProfileLocked,
    size: 'full',
    name: 'oteCents',
    oteInputOne: true,
    value: ote || ote === 0 ? toDollars(ote) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
    autoFocus: autofocusFields.includes('ote_cents'),
    highlight: autofocusFields.includes('ote_cents'),
  };

  const salaryInputProps = {
    disabled: isProfileLocked,
    size: 'full',
    name: 'salaryCents',
    oteInputOne: true,
    value: salary || salary === 0 ? toDollars(salary) : '',
    placeholder: '',
    moneyInput: true,
    className: styles.oteInputContainer,
    handleInputChange: (event) =>
      handleCandReqInputChange({
        ...event,
        target: {
          ...event.target,
          value: toCents(event.target.value),
          name: event.target.name,
        },
      }),
    autoFocus: autofocusFields.includes('salary_cents'),
    highlight: autofocusFields.includes('salary_cents'),
  };

  const locationNotesInputProps = {
    type: 'textarea',
    size: 'full',
    rows: '3',
    handleInputChange: handleCandReqInputChange,
    name: 'locationNotes',
    value: locationNotes,
    className: styles.oteInputContainer,
    placeholder: '"I am open to anywhere in the Bay Area" e.g.',
  };

  return (
    <div className={cn(styles.basicInfoContainer, className)}>
      <div className={styles.basicInfoColumnInputs}>
        <div className={styles.formRow}>
          <Label required className={styles.label}>
            Desired Base Salary
          </Label>
          <Label required className={styles.label}>
            Desired OTE
          </Label>
        </div>
        <div className={styles.formRow}>
          <Input {...salaryInputProps} />
          <Input {...oteInputProps} />
        </div>

        <div className={styles.formRow}>
          <div className={styles.bottomLeft}>
            <Label required className={styles.label}>
              What kind of roles are you looking for?
            </Label>
            <DropdownSelectMany {...rolesInputProps} />
          </div>
          <div className={styles.bottomLeft}>
            <WorkModelSelectMany
              label="Desired Work Model"
              workModel={workModel}
              onChange={handleCandReqWorkModelChange}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.bottomLeft} style={{ marginRight: 0 }}>
            <Label className={styles.label}>Notes</Label>
            <Input {...locationNotesInputProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandReqInputs;
