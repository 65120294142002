import React from 'react';
import classnames from 'classnames';

import { WaterfallHOC } from 'containers/HOC';

import styles from './TableRow.scss';

const TableRow = (props) => {
  const { rowType, children, className, highlight, disabled, onClick, alignLeft } = props;

  const classes = classnames(className, styles.TableRow, {
    [styles.adminReqRow]: rowType === 'adminReqRow',
    [styles.reqRow]: rowType === 'reqRow',
    [styles.dashboardRow]: rowType === 'dashboardRow',
    [styles.decidedRow]: rowType === 'decidedRow',
    [styles.highlight]: highlight,
    [styles.disabled]: disabled,
    [styles.clickable]: Boolean(onClick),
    [styles.alignLeft]: alignLeft,
  });

  const rowProps = {
    className: classes,
    onClick,
  };

  return <div {...rowProps}>{children}</div>;
};

export default WaterfallHOC()(TableRow);
