import React, { useCallback, useEffect, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';

import Block from 'components/Block';
import Input from 'components/Input';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import LandingPageImageUploader from 'components/LandingPageImageUploader';
import InputCheckboxSlider from 'components/InputCheckboxSlider';
import InputRichText from 'components/InputRichText';
import InputSelect from 'components/InputSelect';
import DropdownSelectMany from 'components/DropdownSelectMany';

import getEmployerLandingPage from 'api/landingPages/getEmployerLandingPage';
import patchEmployerLandingPage from 'api/landingPages/patchEmployerLandingPage';

import { promisePickerOptions } from 'connectors/Defaults/promises';

import styles from './EmployerEditorLandingPage.scss';

const EmployerEditorLandingPage = ({ employer: { id: employerId } = {} }) => {
  const [landingPageData, setLandingPageData] = useState({});
  const [employerSaving, setEmployerSaving] = useState(false);

  const { id, enabled, slug, title, content, picture, role, desired_role = [] } = landingPageData;

  const enableLandingPage = (id, enabled = true) => {
    return patchEmployerLandingPage(id, {
      data: {
        type: 'public_landing_pages',
        attributes: {
          enabled: enabled,
        },
      },
    });
  };

  const handleEmployerInputChange = useCallback(
    debounce((event) => {
      const {
        target: { name, value },
      } = event;

      setEmployerSaving(true);
      return patchEmployerLandingPage(id, {
        data: {
          type: 'public_landing_pages',
          attributes: {
            [name]: value,
          },
        },
      }).finally(() => setEmployerSaving(false));
    }, 500),
    [id]
  );

  const handleEmployerInputChangeRTE = useCallback(
    async (event) => {
      const {
        target: { name, value },
      } = event;

      setEmployerSaving(true);
      return patchEmployerLandingPage(id, {
        data: {
          type: 'public_landing_pages',
          attributes: {
            [name]: value,
          },
        },
      }).finally(() => setEmployerSaving(false));
    },
    [id]
  );

  const loadLandingPage = useCallback(() => {
    getEmployerLandingPage(employerId).then(({ data: { public_landing_page = {} } }) => {
      setLandingPageData(public_landing_page);
    });
  }, [employerId]);

  useEffect(() => {
    loadLandingPage();
  }, [loadLandingPage]);

  const [roleOptions, setRoleOptions] = useState([]);

  const selectedRoles = desired_role.map((role) => ({ role, type: 'role' }));

  useEffect(() => {
    promisePickerOptions().then((result) => {
      const { role } = result;
      setRoleOptions(role);
    });
  }, []);

  const imageUploadStarted = useCallback(
    ({ picture }) => {
      setEmployerSaving(true);
      patchEmployerLandingPage(id, {
        data: {
          type: 'public_landing_pages',
          attributes: {
            picture: {
              file_data: picture,
            },
          },
        },
      }).finally(() => {
        setEmployerSaving(false);
      });
    },
    [id]
  );

  const savingContent = employerSaving ? (
    <div className={styles.autoSaving}>Saving...</div>
  ) : (
    <div className={styles.autoSaved}>Profile Saved</div>
  );

  const saveContent = employerSaving === undefined ? null : savingContent;

  const titleBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    className: styles.titleBlock,
  };

  const basicInfoBlockProps = {
    addWhiteBG: true,
    boxShadow: true,
    addPadding: true,
    addChildFlex: true,
    className: styles.titleBlock,
  };

  const rolesOptions = roleOptions.map((role) => ({
    indent: 0,
    label: role,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'role',
      role,
    },
  }));

  const recentRolesSource = useMemo(
    () => [
      { label: 'None', value: null },
      ...roleOptions.map((loc) => ({ label: loc, value: loc })),
    ],
    [roleOptions]
  );

  return (
    <LayoutWithoutSidebar
      content={
        <div className={styles.EditorProfile}>
          <Block {...titleBlockProps}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Public Landing Page</div>
              <div className={styles.saveContent}>{saveContent}</div>
            </div>
          </Block>
          <Block {...basicInfoBlockProps}>
            <div className={styles.containerFlexRow}>
              <LandingPageImageUploader
                landingPageId={id}
                pictureUrl={picture}
                imageUploadStarted={imageUploadStarted}
              />
              <div className={styles.sliderContainer}>
                <InputCheckboxSlider
                  name="landingPageEnabled"
                  checked={enabled}
                  handleInputChange={(event) => {
                    const {
                      target: { checked },
                    } = event;

                    enableLandingPage(id, checked).then(() => {
                      loadLandingPage();
                    });
                  }}
                  label={enabled ? 'Enabled' : 'Disabled'}
                />
              </div>
            </div>
          </Block>
          <Block {...basicInfoBlockProps}>
            <div className={styles.basicInfoTop}>
              <Label className={styles.label}>
                <div>Title</div>
              </Label>
              <Input
                handleInputChange={handleEmployerInputChange}
                type="text"
                size="xlarge"
                name="title"
                value={title}
                placeholder="Title"
                autoFocus={true}
                className={styles.noPadding}
              />
            </div>
            <div className={styles.basicInfoTop}>
              <Label className={styles.label}>
                <div>Content</div>
              </Label>
              <InputRichText
                key={content || 'RTE'}
                debounceTime={500}
                handleInputChange={handleEmployerInputChangeRTE}
                name="content"
                value={content}
                placeholder="Content"
              />
            </div>
            <div className={styles.basicInfoTop}>
              <Label className={styles.label}>
                <div>Slug</div>
              </Label>
              <Input
                handleInputChange={handleEmployerInputChange}
                type="text"
                size="xlarge"
                name="slug"
                value={slug}
                placeholder="Slug"
                autoFocus={true}
                className={styles.noPadding}
              />
            </div>
            <div className={styles.basicInfoTop}>
              <Label className={styles.label}>Desired roles</Label>
              <DropdownSelectMany
                className={styles.searchBarFilter}
                defaultIcon="caret-down"
                activeIcon="caret-up"
                onChange={({ value: { role } }) => {
                  const newRoles = desired_role.includes(role)
                    ? desired_role.filter((r) => r !== role)
                    : [...desired_role, role];

                  handleEmployerInputChangeRTE({
                    target: { name: 'desired_role', value: newRoles },
                  }).then(loadLandingPage);
                }}
                optionsLarge={true}
                source={rolesOptions}
                name="desired_role"
                isLocation={true}
                selectedValues={selectedRoles}
                defaultLabel="Click to select a role"
              />
            </div>
            <div className={styles.basicInfoTop}>
              <Label className={styles.label}>Recent role</Label>
              <InputSelect
                source={recentRolesSource}
                name="recent_role"
                handleInputChange={({ target: { value } }) => {
                  handleEmployerInputChangeRTE({
                    target: { name: 'role', value },
                  }).then(loadLandingPage);
                }}
                value={role || ''}
                defaultLabel="Select a role"
                defaultIcon="caret-down"
                noMargin={true}
                bigInput={true}
              />
            </div>
          </Block>
        </div>
      }
    />
  );
};

export default EmployerEditorLandingPage;
