import React, { useState, useMemo, useEffect } from 'react';

import Label from 'components/Label';
import DropdownSelectMany from 'components/DropdownSelectMany';

import { promisePickerOptions } from 'connectors/Defaults/promises';

import styles from './styles.scss';

export const WorkModelSelectMany = ({
  workModel,
  onChange,
  noLabel = false,
  label = 'Work Model',
}) => {
  const [workModelsOptions, setWorkModelsOptions] = useState([]);

  const selectedWorkModel = useMemo(
    () => workModel.map((workModel) => ({ workModel, type: 'workModel' })),
    [workModel]
  );

  useEffect(() => {
    promisePickerOptions().then(({ workModels }) =>
      setWorkModelsOptions(
        workModels.map((workModel) => ({
          indent: 0,
          label: workModel,
          optionType: 'workModel',
          subCategories: [],
          value: {
            type: 'workModel',
            workModel,
          },
        }))
      )
    );
  }, []);

  const workModelProps = {
    className: styles.searchBarFilter,
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    onChange: ({ value, label, optionType }) => {
      onChange({
        target: {
          label,
          value,
          name: 'workModel',
          optionType,
        },
      });
    },
    optionsLarge: true,
    source: workModelsOptions,
    key: 'workModel',
    isLocation: true,
    selectedValues: selectedWorkModel || '',
    defaultLabel: label,
    name: 'workModel',
  };

  return (
    <>
      {!noLabel && <Label className={styles.label}>{label}</Label>}
      <DropdownSelectMany {...workModelProps} />
    </>
  );
};
