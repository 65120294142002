import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';

import { redirectHomepage } from 'utils/navHelpers/index';

import ConnectContainer from 'containers/ConnectContainer';

import ApplyForm from 'components/ApplyForm';
import TableOverlay from 'components/TableOverlay';

import getPositionsData from 'api/landingPages/getPositionsData';

import duck from '../redux';

import applyLogo from './assets/apply-logo.png';

import styles from './ApplyEmployer.scss';
import { PositionsList } from './components/PositionsList/PositionsList';

export const ApplyPositions = (props) => {
  const {
    actions: { applyStarted, saveUTMInfo },
  } = props;

  const location = useLocation();
  const { search } = location;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (search) {
      const utmInfo = queryString.parse(search);
      saveUTMInfo({ utmInfo });
    }
  }, [saveUTMInfo, search]);

  const [landingPagesData, setLandingPagesData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPositionsData().then(({ data: { public_landing_pages } }) => {
      setLandingPagesData(public_landing_pages);
      setLoading(false);
    });
  }, []);

  const handleSubmit = async (values) => {
    const { firstName, lastName, email, linkedin, password, phone } = values;

    const applyData = {
      firstName,
      lastName,
      email,
      linkedinUrl: linkedin,
      password,
      phone,
    };

    applyStarted({ applyData });
  };

  const { user, hasSeenApproved, isApproved } = props;

  const redirectPath = redirectHomepage(user, isApproved, hasSeenApproved, false, undefined);

  const metaTitle = 'Sales Jobs in Tech | Rainmakers';
  const metaDescription =
    'Sign up with Rainmakers to tap into a premium career network and connect with top tech companies in search of dynamic sales professionals. Various sales positions available!';
  const canonicalUrl = `https://${process.env.HOST}/apply-now/`;

  const helmetProps = {
    key: 'helmet',
    title: metaTitle,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: metaTitle },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (loading) return <TableOverlay />;

  return (
    <>
      <Helmet {...helmetProps} />
      <div className={styles.Container}>
        <div className={styles.marketingContainer}>
          <PositionsList data={landingPagesData} />
        </div>
        <div className={styles.applyFormContainer}>
          <div className={styles.formWrapper}>
            <ApplyForm handleSubmit={handleSubmit} oneLineName={true} isEmployerApplyNow={true} />
          </div>
          <div className={styles.logoWrapper}>
            <img src={applyLogo} alt="reinmakers logo" className={styles.logo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectContainer(duck)(ApplyPositions);
